import * as React from "react"

import './global.scss';
import Styles from './index.module.scss';
import earthImg from '../images/earth.gif';

// markup
const IndexPage = () => {
  return (
    <main className={Styles['container']}>
      <title>58parks</title>
      <img
        alt="Earth"
        src={earthImg}
      />
    </main>
  )
}

export default IndexPage
